.navigation { top: 0; z-index: 999999; width: 260px; overflow-x: none; overflow-y: auto; font-family: $font_child; font-size: 15px; background: #1071b6; color: #fff; padding: 0;}
.navigation .navigation-inner { padding: 0 0 15px }
.navigation .navigation-inner>p { margin-left: 15px; margin-right: 15px }
.navigation.right { left: auto; right: -260px }
.navigation.left { left: -260px; right: auto }
.navigation h1, .navigation h2, .navigation h3, .navigation h4, .navigation h5, .navigation h6 { font-size: 11px; font-weight: normal; padding: 0 15px; margin: 0 0 5px; color: #333; line-height: 24px; background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #ffffff), color-stop(100%, #dfdfdf)); background-image: -webkit-linear-gradient(#ffffff, #dfdfdf); background-image: -moz-linear-gradient(#ffffff, #dfdfdf); background-image: -o-linear-gradient(#ffffff, #dfdfdf); background-image: linear-gradient(#ffffff, #dfdfdf); -webkit-box-shadow: 0 5px 5px 3px rgba(0,0,0,0.2); -moz-box-shadow: 0 5px 5px 3px rgba(0,0,0,0.2); box-shadow: 0 5px 5px 3px rgba(0,0,0,0.2) }
.navigation p { font-size: 13px; margin: 0 0 12px }
.navigation p a { color: rgba(51,51,51,0.9) }
.navigation>p { margin-left: 15px; margin-right: 15px }
.navigation ul { display: block; margin: 0 0 15px; padding: 0; }
.navigation ul li { display: block; margin: 0; line-height: 48px; }
.navigation ul li:hover, .navigation ul li.active, .navigation ul li.navigation-class-active { border-top: none; line-height: 49px }
.navigation ul li:hover>a, .navigation ul li:hover>span, .navigation ul li.active>a, .navigation ul li.active>span, .navigation ul li.navigation-class-active>a, .navigation ul li.navigation-class-active>span { background: #4796d8; }
.navigation ul li a, .navigation ul li span { padding: 0 15px; display: block; text-decoration: none; color: #fff; }
.navigation ul li ul { margin: 0; background: #4796d8; }
.navigation ul li ul li { line-height: 40px; font-size: 13px;}
.navigation ul li ul li:last-child { border-bottom: none }
.navigation ul li ul li:hover, .navigation ul li ul li.active, .navigation ul li ul li.navigation-class-active { border-top: none; line-height: 41px; background:#5aa0db!important; }
.navigation ul li ul li:hover>a, .navigation ul li ul li:hover>span, .navigation ul li ul li.active>a, .navigation ul li ul li.active>span, .navigation ul li ul li.navigation-class-active>a, .navigation ul li ul li.navigation-class-active>span {  }
.navigation ul li ul li a, .navigation ul li ul li span { color: #fff; padding-left: 30px }
.navigation form { margin: 0 15px }
.navigation label { font-size: 13px }
.navigation input[type="text"], .navigation input[type="password"], .navigation input[type="date"], .navigation input[type="datetime"], .navigation input[type="email"], .navigation input[type="number"], .navigation input[type="search"], .navigation input[type="tel"], .navigation input[type="time"], .navigation input[type="url"], .navigation textarea, .navigation select { width: 100%; font-size: 13px; padding: 5px; -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; margin: 0 0 10px; -webkit-border-radius: 2px; -moz-border-radius: 2px; -ms-border-radius: 2px; -o-border-radius: 2px; border-radius: 2px; border: none; background: rgba(0,0,0,0.1); color: rgba(51,51,51,0.6); display: block; clear: both }
.navigation input[type=checkbox] { width: auto; display: inline; clear: none }
.navigation input[type=button], .navigation input[type=submit] { color: #f8f8f8; background: #333 }
.navigation input[type=button]:hover, .navigation input[type=submit]:hover { background: rgba(51,51,51,0.9) }
.nav-pills .notif {
  display: inline-block !important;
  padding: 1px 5px !important;
  width: auto;
  height: auto;
  margin: 0 0 2px 2px;
  bottom: 5px;
}

.navigation_mob{
	display: block;
    @include min-responsive($mntablet){
    	display: none;
    }
}

html{
    // scroll-behavior: smooth;
}
body {
    font-family: $font_body;
    font-size: 14px;
    color: $cl_black;
    background-color: $cl_body;
    margin: 0;
    overflow: auto;
}
img{
    max-width: 100%;
    transition: opacity .3s ease-in;
}
img[data-loaded="true"] {
    opacity: 1;
}
a {
    -webkit-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
    color: inherit;
    outline: none;
}

a:focus {
    text-decoration: none;
    outline: none;
    color: inherit;
}

section {
    position: relative;
    @include min-responsive($mntablet) {
        min-width: 1024px;
    }
}
li{
    list-style: none !important;
}

.text-right {
    text-align: right;
}
.flex-end {
    justify-content:  flex-end;
}

.text-center {
    text-align: center;
}

button,
input {
    outline: none;
}

textarea {
    max-width: 100%;
}

.backtop {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    bottom: 25px;
    right: 30px;
    z-index: 9;
    transition: .2s ease-in-out;
    &__link {
        width: 36px;
        height: 36px;
        padding: 6px 3px 3px;
        margin: 0;
        line-height: 1;
        font-size: 22px;
        background: rgba(255, 255, 255, 1);
        // border-radius: 30px;
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        color: $cl_blue;
        text-align: center;
        vertical-align: middle;
        border: 0px solid #000;
        display: block;
        box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
        span {
            width: 30px;
            height: 30px;
            display: block;
            // border-radius: 20px;
            // background: #fff;
            // border: 2px solid #fff;
        }
        &:hover {
            color: #2a2a2a;
        }
    }
    &--show {
        visibility: visible;
    opacity: 1;
    }
}

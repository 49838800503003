.tabs {
    display: block;
    width: 100%;
    transform: translateY(75px);
    height: 240px;

    &__container {
        width: 950px;
        margin: 0 auto;
        padding-top: 20px;
        padding-bottom: 20px;
        @include max-responsive($mntablet) {
            width: 100%;
        }
    }
    &__box{
        margin-top: 30px;
        background: white;
        border-radius: 15px;
        @include max-responsive($mntablet){
            margin-top: 75px;
        }
    }
    &>li {
        transition-duration: .25s;
        list-style: none;
        text-align: center;
        padding: 0;
        position: relative;
        // overflow: hidden;
        cursor: pointer;
        color: white;
        border-radius: 25px;
        display: inline-block;
        width: 32%;

        &:hover {
            &:before {
                -webkit-transform: translateY(70%);
                transform: translateY(70%);
            }
        }

        &.active {
            color: rgb(80, 85, 90);

            &:before {
                transition-duration: .5s;
                background-color: white;
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }

            .tabs__list__title {
                background: white;
            }
        }
    }

    &__img {
        margin-top: 20px;
        @include max-responsive($mntablet) {
            margin-top: 0;
            margin-bottom: 50px;
        }
        img {
            max-width: 100%;
        }
    }

    &__wrapper {
        width: 100%;
        margin: 0 auto;
        background: transparent;
    }

    &__content {
        position: relative;
        width: 100%;
        border-radius: 15px;
        height: 0;
        background: transparent;
        margin-bottom: 100px;

        @include max-responsive($mntablet) {
            min-height: 200px;
        }
        > .d_active{
            display: block;
        }
        &>li {
            width: 100%;
            // position: absolute;
            // top: 0;
            // left: 0;
            display: none;
            list-style: none;
            // &.active{
            //     display: block;
            // }
            @include max-responsive($mntablet) {
                width: calc(100% - 10px);
                left: 50%;
                transform: translateX(-50%);
                background: white;
                border-radius: 15px;
                position: relative;
            }

            .content__wrapper {
                text-align: center;
                border-radius: 5px;
                width: 100%;
            }
        }
    }

    &__inline {
        display: flex;
        padding: 20px;
        @include max-responsive($mntablet) {
            flex-direction: column;
            margin-top: 0;
        }

        &__item {
            text-align: left;
        }
    }

    &__list {
        position: relative;
        &__title {
            z-index: 1;
            position: relative;
            transition: all .2s linear .2s;
            padding: 20px;
            border-radius: 25px;
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 30px 17.5px 0 17.5px;
            border-color: $cl_lblue transparent transparent transparent;
            z-index: 1;
            opacity: 0;
            transition: .5s ease-in-out;
        }

        &.active {
            &::after{
                opacity: 1;
                bottom: -50px;
            }
        }
        &__acc{
            .tabs__list__title{
                width: calc(100% - 10px);
                max-width: 400px;
                margin: 15px auto;
                padding: 10px 20px;
                @include max-responsive($mntablet){
                    // padding: 10px 20px 20px;
                    // border-bottom: 1px solid #a0a0a0;
                    // border-radius: 0;
                    &:first-child{
                        // border-top: 1px solid #a0a0a0;
                    }
                }
            }
            &.active{
                .tabs__list__title{
                    background: white;
                    border: none;
                    border-radius: 15px;
                }
            }
        }
    }
}

.tabs__list .tab .content__wrapper {
    h2 {
        width: 100%;
        text-align: center;
        padding-bottom: 20px;
        font-weight: 300;
    }

    img {
        width: 100%;
        height: auto;
        border-radius: 5px;
    }
}
.tagline {
    position: relative;
    min-height: 435px;
    overflow: hidden;
    background: white;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 85px;
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        z-index: 1;
    }

    &__list {
        margin: 0;
        padding: 0;
        position: relative;
        visibility: hidden;

        .slick {
            &-dots {
                bottom: 20px;

                button {
                    &::before {
                        font-size: 100px;
                        color: $cl_grey;
                        opacity: 1;
                    }

                    &:hover,
                    &:focus {
                        &::before {
                            color: $cl_grey;
                        }
                    }
                }

                >.slick-active {
                    button {
                        &::before {
                            color: $cl_blue;
                        }

                    }
                }
            }

            &-slide {
                margin-bottom: -5px;

                img {
                    width: 100%;
                    object-fit: cover;
                    object-position: center;

                    @include max-responsive($mntablet) {
                        // height: calc(60vh - 110px);
                    }

                    @include max-responsive($mxphone) {
                        height: calc(100vh - 118px);
                        margin: 0 auto;
                    }

                    @media (max-height: 812px) and (min-height: 760px) {
                        height: calc(100vh - 250px);
                    }
                }
            }
        }
    }

    &__item {
        height: 100vh;
        text-align: center;
        background-size: cover !important;
        background-position: center;
        @include max-responsive($mxphone) {
            display: table!important;
            height: calc(100vh - 50px);
            padding-bottom: 100px;
        }
        img {
            object-fit: cover;
            object-position: center;

            @include max-responsive($xsphone) {
                min-height: 90vh;
            }
        }
    }

    &__text {
        position: absolute;
        width: auto;
        max-width: 480px;
        margin: 0 auto;
        color: white;
        bottom: 15%;
        left: 7%;
        text-align: left;
        @include max-responsive($mxphone){
            position: relative;
            display: table-cell;
            vertical-align: bottom;
            width: 85%;
            bottom: auto;
            left: auto;
            text-align: center;
        }
        &--big {    
            color: white;
            background: $cl_blue;
            padding: 10px;
            text-transform: uppercase;
            color: white;
            font-size: 4em;
            font-weight: 800;
            font-family: $font_primary;
            line-height: 1.2;
            text-align: left;
            display: inline-block;
            @include max-responsive($mxphone) {
                text-align: center;
                font-size: 3em;
            }
        }

        &--small {
            padding: 10px 0;
            max-height: 200px;
            overflow: hidden;
            color: $cl_blue;
            background: rgba(255, 255, 255, .8);
            padding: 10px;
            width: 105%;
            font-size: 1.4em;
            @include max-responsive($mxphone) {
                font-size: 1.2em;
                width: 105%;
                transform: translateX(-2.5%);
            }
        }
    }

    &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .3);
    }
}
.panel {
    display: block;
    position: relative;
    overflow: hidden;
    background: url('../img/panel-footer.png') no-repeat;
    background-position: bottom right;

    &__side {
        display: flex;
        align-items: flex-start;
        @include max-responsive($mntablet){
            flex-direction: column-reverse;
        }
        &--right{
            flex-direction: row-reverse;
            @include max-responsive($mntablet){
                flex-direction: row;
            }
        }
    }

    &__overlay {
        position: relative;
        transform: translateY(-80px);
        z-index: 1;
    }

    &__ornament {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &--left {
            position: absolute;
            bottom: -50px;
            left: -50px;
            transform: scale(.8);
            z-index: 0;
        }

        &--right {
            position: absolute;
            top: -50px;
            right: -50px;
            transform: scale(.8) rotate(180deg);
            z-index: 0;
        }

        &--white {
            .panel__ornament--left {
                bottom: -3px;
                left: -0px;
                transform: scale(1);

                @include max-responsive($mnlarge) {
                    bottom: -93px;
                    left: -90px;
                    transform: scale(.6);
                }
            }

            .panel__ornament--right {
                top: -3px;
                right: -0px;
                transform: scale(1) rotate(180deg);

                @include max-responsive($mnlarge) {
                    top: -90px;
                    right: -93px;
                    transform: scale(.6) rotate(180deg);
                }
            }
        }

        &--news {
            .panel__ornament--left {
                bottom: -5px;
                left: -35px;
                transform: scale(1)
            }

            .panel__ornament--right {
                top: -5px;
                right: -35px;
                transform: scale(1) rotate(180deg);
            }
        }
    }

    &__wrapper {
        width: 100%;
    }

    &__title {
        font-family: $font_primary;
        margin: 40px 0;
        position: relative;
        display: inline-block;
        z-index: 1;

        &__text {
            text-transform: uppercase;
            font: 900 48px/1.2 $font_primary;
            color: $cl_blue;
            @include max-responsive($mnphone){
                font-size: 40px;
            }
            @include max-responsive($xsphone){
                font-size: 34px;
            }
        }

        p {
            color: #fff;
        }

        &--lstripe {
            &::before {
                content: '';
                position: absolute;
                bottom: -25px;
                left: 0;
                width: 50px;
                height: 10px;
                background: $cl_lblue;
            }
        }
        &--ceo{
            @include min-responsive($mntablet){
                top: 95px
            }
        }
    }

    &__subtitle {
        margin: 20px 0;
    }

    &__img {
        margin: 25px 0;
        display: flex;
        justify-content: center;

        img {
            max-width: 100%;
        }
    }

    &__content {
        padding-left: 20px;
        @include max-responsive($mntablet) {
            padding-left: 0 !important;
        }
        &.pl50 {
            @include max-responsive($mntablet) {
                padding-left: 0 !important;
            }
        }
    }

    &__paragraph {
        position: relative;
        margin-top: 50px;

        p {
            line-height: 1.8;
        }

        a {
            color: white;
            font-weight: 700;
        }

        strong {
            font-weight: 300;
            font-size: 23px;
        }
    }

    &__text {
        font: 500 18px/1.5 $font_body;
        color: black;
        margin: 40px 0;

        @include max-responsive($mntablet) {
            font-size: 16px;
        }

        &__wrapper {
            margin: 50px 0;
            @include font-pack($family: $font_body, $size: 18px, $weight: 300, $lh: false, $colour: black);

            .scroll-bar {
                background-color: #403e3e !important;
            }

            br {
                content: "";
                display: block;
                margin: 10px;
            }

            &--black {
                p {
                    color: black !important;
                }
            }

        }

        &--big {
            font-size: 20px;
            @include max-responsive($mntablet) {
                font-size: 16px;
            }
        }

        &--lstripe {
            &::before {
                content: '';
                position: absolute;
                top: -30px;
                left: 0;
                width: 50px;
                height: 10px;
                background: $cl_lblue;
            }
        }
    }

    &__board {
        margin: 10px 0;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: -25px;
            left: 0px;
            width: 50px;
            height: 10px;
            background: $cl_lblue;
        }

        &__name {
            font: 700 40px/1.2 $font_primary;
            color: $cl_blue;

            @include max-responsive($mntablet) {
                color: black;
                font-size: 24px;
            }
        }

        &__group {
            @include font-pack($family: $font_title, $size: 1.5em, $weight: 500, $lh: false, $colour:$cl_lblue);
        }
    }

    &__founders {
        font: 500 24px/1.2 $font_body;
        color: black;
        text-transform: uppercase;
        margin: 10px 0;

        @include max-responsive($mntablet) {
            font-size: 20px;
        }

        &__job {
            @include font-pack($family: $font_body, $size: 16px, $weight: 300, $lh: false, $colour:black);
        }
    }

    &--ceo {
        padding-bottom: 10vh;
        min-height: calc(100vh - 68px);
    }

    &__inline {
        display: inline-block;
    }
}
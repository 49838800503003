@keyframes updown {
    0% {
        -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
        box-shadow: 0 0 rgba(251, 175, 93, 1)
    }
    50% {
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
    100% {
        -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
}

@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
}

@-webkit-keyframes pulse {
    0% {
        transform: scale(.9);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 20px rgba(#5a99d4, 0);
    }
    100% {
        transform: scale(.9);
        box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
    }
}

@-webkit-keyframes b {
    0%,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -1000px, 0);
        transform: translate3d(0, -1000px, 0)
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0)
    }
    75% {
        -webkit-transform: translate3d(0, 1px, 0);
        transform: translate3d(0, 1px, 0)
    }
    90% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
    to {
        -webkit-transform: none;
        transform: none
    }
}

@keyframes b {
    0%,
    60%,
    75%,
    90%,
    to {
        -webkit-animation-timing-function: cubic-bezier(.215, .61, .355, 1);
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -1000px, 0);
        transform: translate3d(0, -1000px, 0)
    }
    60% {
        opacity: 1;
        -webkit-transform: translate3d(0, 5px, 0);
        transform: translate3d(0, 5px, 0)
    }
    75% {
        -webkit-transform: translate3d(0, 1px, 0);
        transform: translate3d(0, 1px, 0)
    }
    90% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
    to {
        -webkit-transform: none;
        transform: none
    }
}
@keyframes updown {
    0% {
        -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
        box-shadow: 0 0 rgba(251, 175, 93, 1)
    }
    50% {
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
    100% {
        -moz-transform: translateY(10px);
        -ms-transform: translateY(10px);
        -webkit-transform: translateY(10px);
        transform: translateY(10px);
    }
}
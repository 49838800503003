@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700;800&display=swap');

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?k95tau');
  src:  url('../fonts/icomoon/icomoon.eot?k95tau#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?k95tau') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?k95tau') format('woff'),
    url('../fonts/icomoon/icomoon.svg?k95tau#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right:before {
  content: "\e90b";
}
.icon-arrow-left:before {
  content: "\e90a";
}
.icon-chevron-down:before {
  content: "\e905";
}
.icon-cheveron-outline-right:before {
  content: "\e90c";
}
.icon-cheveron-outline-left:before {
  content: "\e90d";
}
.icon-chevron-thin-right:before {
  content: "\e903";
}
.icon-chevron-thin-left:before {
  content: "\e902";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-close:before {
  content: "\e904";
}
.icon-arrow-up2:before {
  content: "\ea3a";
}
.icon-search:before {
  content: "\e901";
}
.icon-pinterest-p:before {
  content: "\e900";
}
.icon-google-plus:before {
  content: "\e90e";
}

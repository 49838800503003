.infokita{
    .card {
        margin: 50px auto;
        position: relative;
        display: block;
        &__wrapper {
            display: flex;
            flex-wrap: wrap;
            // justify-content: space-between;
            margin: 0 -10px;
        }
        &__item {
            flex-basis: 33.33%;
            height: 360px;
            position: relative;
            // margin-right: 20px;
            padding: 0 20px;
            margin-bottom: 80px;
            @include max-responsive($mntablet){
                flex-basis: 50%;
            }
            @include max-responsive($mxphone){
                flex-basis: 100%;
            }
            a{
                display: contents;
            }
        }
        &__link {   
            &:hover {
                .card__overlay {
                    opacity: 1;
                }
                .card__title {
                    color: $cl_blue;
                    font-weight: 700;
                }
            }
        }
        &__overlay {
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            text-align: center;
            position: absolute;
            top: 0%;
            left: 5%;
            color: white;
            padding-top: 75px;
            height: 100%;
            width: 90%;
            background: rgba(14, 53, 123, 0.58);
            opacity: 0;
            transition: .2s opacity ease-in-out;
            &::before {
                content: '';
                width: 75px;
                height: 75px;
                background: url('../img/download.png');
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                margin-left: 3px;
                top: 37%;
                left: 51%;
                transform: translateX(-50%);
            }
            &::after {
                // content: 'unduh';
                // color: black;
                // padding-top: 95px;
            }
        }
        &__box {
            background-size: cover !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
            height: 100%;
        }
        &__title {
            position: relative;
            display: block;
            margin: 20px 0;
            padding: 5px 10px;
            letter-spacing: 2px;
            @include font-pack($family: $font_body, $size: 15px, $weight: 400, $lh: false, $colour: black);
            text-align: center;
            transition: all .2s ease-in-out;
        }
    }
}
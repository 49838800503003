.accordion__filter {
    padding-bottom: 10px;
        list-style: none;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    &--contact {
        label {
            text-align: left;
        }
    }
    li {
        color: #AAAAAA;
        display: block;
        position: relative;
        float: left;
        width: 100%;
        height: auto;
    }

    li input[type=radio] {
        position: absolute;
        visibility: hidden;
    }

    li label {
        display: block;
        position: relative;
        font-weight: 300;
        font-size: 16pxem;
        padding: 10px 10px 10px 70px;
        margin: 5px auto;
        height: 30px;
        z-index: 9;
        cursor: pointer;
        font-size: 13px;
        -webkit-transition: all 0.25s linear;
    } //    li:hover label {
    //        color: #FFFFFF;
    //    }
    li .check {
        display: block;
        position: absolute;
        border: 1px solid #AAAAAA;
        height: 20px;
        width: 20px;
        top: 14px;
        left: 45px;
        z-index: 5;
        transition: border .25s linear;
        background: $cl_fog;
        -webkit-transition: border .25s linear;
    } //    li:hover .check {
    //        border: 5px solid #FFFFFF;
    //    }
    li .check::before {
        display: block;
        position: absolute;
        content: '';
        top: 0px;
        left: 5px;
    }
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;

    input[type=radio]:checked ~ .check {
        border: 1px solid $cl_dgrey;
    }

    input[type=radio]:checked ~ .check::before {
        background: transparent;
        content: 'X';
        color: $cl_dgrey;
    }

    input[type=radio]:checked ~ label {
        color: $cl_dgrey;
        font-weight: 600;
    }
}

.my-popup {
  /*   musthave style */
  position: fixed;
  left: 0;
  top: 15%;
  bottom: 20%;
  right: 0;
  width: 50%;
  z-index: 2;
  margin: 0 auto;
  /*   custom style */
  background: #fff;
  padding: 30px;
  overflow: hidden;
  /*   hide element */
  display: none;
  z-index: 123;
  @include max-responsive($mnlarge) {
    height: 540px;
    padding: 0 30px;
  }
  @include max-responsive($mnphone) {
    width: calc(100% - 20px);
    padding: 0;
    top: 3%;
  }
}

/* custom */

.open-popup {
  cursor: default;
  color: blue;
}

/* close popup element */

.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 12px !important;
  cursor: pointer;
  color: $cl_lblue;
  border: 2px solid $cl_lblue;
  border-radius: 100px;
  padding: 5px;
  font-size: 18px;
}

/*class for parent popup */

.popup-bg {
  display: none;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}
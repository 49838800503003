.pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 15px;
    padding-right: 20px;
    &__link {
        margin: 5px 7px;
        background: transparent;
        position: relative;
        transition: all .4s ease-in-out;
        @include font-pack($family: $font_primary, $size: 18px, $weight: 400, $lh: false, $colour: $cl_grey);
        padding: 2px 12px;
        transition: all .4s ease-in-out;
        &--active {
            border-radius: 100%;
            background: white;
            .pagination__item {
                color: white;
            }
        }
        &:hover {
            border-radius: 100%;
            background: white;
            .pagination__item {
                color: black;
            }
        }
        &::before {
            content: '/';
            position: absolute;
            top: 0;
            right: -12px;
            font-size: 20px;
            color: $cl_dgrey;
        }
        &:last-child {
            &::before {
                content: none;
            }
        }
    }
    &__item {}
    &--white {
        .pagination__link {
            color: $cl_dgrey;
            &--active {
                border-radius: 100%;
                background: $cl_blue;
                color: white;
            }
            &:hover {
                border-radius: 100%;
                background: $cl_blue;
                color: white;
            }
        }
    }
}
.history {
    margin: 0px auto;
    padding: 100px 0;
    &__bg {
        background: url('../img/history-bg.jpg');
        background-attachment: fixed;
        background-size: cover;
        background-repeat: no-repeat;
    }
    &__list {
        &:nth-child(odd) {
            .history__year {
                left: 185px;
            }
            .history__connector {
                &::before {
                    content: '';
                    position: absolute;
                    top: 49%;
                    right: -200px;
                    width: 134px;
                    border-top: 5px dotted white;
                }
                span {
                    &::before {
                        content: "";
                        position: absolute;
                        top: 45%;
                        right: -225px;
                        background: transparent;
                        padding: 5px;
                        border-radius: 50%;
                        border: 4px solid white;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        top: 51%;
                        right: -218px;
                        background: white;
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                    }
                }
            }
        }
        &:nth-child(even) {
            .history__year {
                right: 185px;
            }
            .history__connector {
                &::before {
                    content: '';
                    position: absolute;
                    top: 49%;
                    left: -200px;
                    width: 134px;
                    border-top: 5px dotted white;
                }
                span {
                    &::before {
                        content: "";
                        position: absolute;
                        top: 45%;
                        left: -225px;
                        background: transparent;
                        padding: 5px;
                        border-radius: 50%;
                        border: 4px solid white;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        top: 51%;
                        left: -218px;
                        background: white;
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                    }
                }
            }
        }
        &:nth-child(odd)::before {
            border-color: transparent white transparent transparent;
        }
        &:nth-child(even)::before {
            border-color: transparent transparent transparent white;
        }
        &::after {
            background: white;
        }
    }
    &__content {
        height: 100px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: black;
        border: 5px solid white;
        color: $cl_1blue;
        background: white;
        position: relative;
        &::before,
        &::after {
            content: '';
            position: absolute;
            top: -5px;
            left: -5px;
            width: 80px;
            height: 140px;
            background-color: white;
            z-index: -1;
        }
        &::before {
            transform: rotate(60deg);
        }
        &::after {
            transform: rotate(-60deg);
        }
    }
    &__year {
        @include font-pack($family: $font_primary, $size: 2em, $weight: 700, $lh: false, $colour: $cl_lblue);
        position: absolute;
        top: -10px;
    }
    &__title {
        @include font-pack($family: $font_primary, $size: 1.3em, $weight: 700, $lh: false, $colour: white);
        text-transform: uppercase;
        position: absolute;
        width: 400px;
        bottom: -35px;
        left: -158px;
    }
    &__logo {
        display: block;
    }
    &__item {
        &--1988 {
            .history__logo {
                top: -20px;
                right: 105px;
            }
        }
        &--1996 {
            .history__logo {
                top: -30px;
                right: 105px;
            }
        }
        &--2005 {
            .history__logo {
                top: -80px;
                right: 105px;
            }
        }
    }
    &__timeline {
        &__bottom {
            @include font-pack($family: $font_primary, $size: 1em, $weight: 700, $lh: false, $colour: white);
            text-transform: uppercase;
            transform: translate(10px, -30px);
                text-align:center;
        }
    }
    &__date {
        position: absolute;
        bottom: -53px;
        color: $cl_lblue;
        width: 400px;
    }
    &__img {
        position: relative;
        &__title {
            position: absolute;
            text-align: left;
            &::after {
                content: '';
                position: absolute;
                top: 5px;
                left: -15px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: $cl_lblue;
            }
        }
        &__text {
            @include font-pack($family: $font_primary, $size: 1.4em, $weight: 700, $lh: false, $colour: white);
            letter-spacing: 3px;
        }
        &--top {
            height: 405px;
            .history__img__title {
                top: 37%;
                right: 6%;
            }
        }
        &__parallax {
            position: absolute;
        }
        .layer {
            &1 {
                top: 0;
                left: 26%;
                z-index: 2;
            }
            &2 {
                top: 0;
                left: 28.6%;
                z-index: 2;
            }
            &3 {
                top: 0;
                left: 31%;
            }
        }
        .hidden {
            display: none;
        }
    }
}










.clearfix {
	*zoom: 1;
	&:after,
	&:before {
		display: table;
		content: "";
		line-height: 0;
	}
	&:after {
		clear: both;
	}
}
.container {
    width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: 15px;
    padding-left: 15px;
	*zoom: 1;
	position: relative;
	@include min-responsive($grid_desktop_large) {
		width: $grid_desktop_large;
	}
}

.leftside.col-lg-8, .rightside.col-lg-8{
	width: 70.562%;
    padding-left: 0;
    padding-right: 15px;
	float: left;
}
.sidebar.col-lg-4, .leftside.col-lg-4{
    width: 29.44%;
    padding-right: 0;
	float: left;
}
.col-20,
.col-30,
.col-40,
.col-50,
.col-70,
.col-100 {
	float: left;
	position: relative;
	padding-right: 10px;
	padding-left: 10px;
	margin: 0;
}
.col-20 {
	width: 100%;
	@include min-responsive($mntablet) {
		width: 25%;
	}
}
.col-30 {
	width: 33.33333% !important;
}
.col-40{
	width: 100%;
	@include min-responsive($mntablet) {
		width: 40% !important;
	}
}
.col-50 {
	width: 100%;
	@include min-responsive($mdtablet) {
		width: 50%;
	}
}
.col-60{
	width: 100%;
	@include min-responsive($mntablet) {
		width: 60% !important;
	}
}
.col-70 {
	width: 760px;
	@include max-responsive($mntablet){
		width: 100%;
	}
}
.col-100 {
	width: $grid_mobile;
}
.col-offset-fluid {
	margin-right: -10px;
	margin-left: -10px;
}
.col-offset-fluid-sm {
	margin-right: 0;
	margin-left: 0;
	@include min-responsive($mntablet) {
		margin-right: -10px;
		margin-left: -10px;	
	}
}
.col-offset-0 {
	padding-right: 0px;
	padding-left: 0px;
}
.col-padding-10 {
	padding: 10px;
}
.mt1 {
	margin-top: 10px;
}
.mt2 {
	margin-top: 20px;
}
.mt3 {
	margin-top: 30px;
}
.mt4 {
	margin-top: 40px;
}

.display-table {
	display: table;
	@include display_full();
	position: relative;
	z-index: 1;
}
.display-center {
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	@include display_full();
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}
.text-center {
	text-align: center;
}

.text-bigger {
	font-size: 1.1em;
}
.text-smaller {
	font-size: .7em;
}
.col-5{
	width: 50%;
	@include max-responsive($mntablet){
		width: 100%;
	}
}
.col-7{
	width: 70%;
	@include max-responsive($mntablet){
		width: 100%;
	}
}
.col-3{
	width: 30%;
	@include max-responsive($mntablet){
		width: 100%;
	}
}
.col-6{
	width: 60%;
	@include max-responsive($mntablet){
		width: 100%;
	}
}
.col-4{
	width: 40%;
	@include max-responsive($mntablet){
		width: 100%;
	}
}
// default transisi
@mixin base_transition($t) {
	-webkit-transition: $t .2s ease;
	-moz-transition: $t .2s ease;
	-ms-transition: $t .2s ease;
	-o-transition: $t .2s ease;
	transition: $t .2s ease;
}
// border radius
@mixin border_radius($x) {
	-webkit-border-radius: $x;
	-moz-border-radius: $x;
	-ms-border-radius: $x;
	-o-border-radius: $x;
	border-radius: $x;
}
// skala
@mixin base_scale($x) {
    -ms-transform: scale($x); /* IE 9 */
    -webkit-transform: scale($x); /* Safari */
    transform: scale($x);
}
// reset style ul
@mixin reset_ul {
	margin: 0;
	padding: 0;
}
// 100%
@mixin display_full {
	width: 100%;
	height: 100%;
}
// untuk overflow judul pada list berita
@mixin title_overflow($height, $max-height) {
	height: $height;
	max-height: $max-height;
	overflow: hidden;
}
// media
@mixin max-responsive ($media) {
  @media (max-width: $media) { @content; }
}
@mixin min-responsive ($media) {
  @media (min-width: $media) { @content; }
}
@mixin range-responsive ($media, $media2) {
  @media (min-width: $media) and (max-width: $media2) { @content; }
}
@mixin range-responsive-height ($media, $media2) {
  @media (min-height: $media) and (max-height: $media2) { @content; }
}
@mixin max-height ($media) {
  @media (max-height: $media) { @content; }
}

// prefix declarations
@mixin prefixed($property, $value) {
  @if $webkit == true {
    -webkit-#{$property}: #{$value};
  }

  @if $moz == true {
    -moz-#{$property}: #{$value};
  }

  @if $ms == true {
    -ms-#{$property}: #{$value};
  }

  @if $o == true {
    -o-#{$property}: #{$value};
  }

  #{$property}: #{$value};
}
// prefix keyframes
@mixin keyframes($name) {
  @if $webkit == true {
    @-webkit-keyframes #{$name} {
      @content;
    }
  }

  @if $moz == true {
    @-moz-keyframes #{$name} {
      @content;
    }
  }

  @if $ms == true {
    @-ms-keyframes #{$name} {
      @content;
    }
  }

  @if $o == true {
    @-o-keyframes #{$name} {
      @content;
    }
  }

  @keyframes #{$name} {
    @content;
  }
}

// font template
@mixin font-pack($family: false, $size: false, $weight: false,  $lh: false, $colour: false) {
    @if $family { font-family: $family; }
    @if $size { font-size: $size; }
    @if $weight { font-weight: $weight; }
    @if $lh { line-height: $lh; }
    @if $colour { color: $colour; }
}

@mixin rotate($deg){
    transform: rotate($deg);
}
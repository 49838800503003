.ftr_des {
    font-family: $font_primary;
    background: $cl_blue;
    color: white;
    margin-top: 100px;
    padding: 5px 0;
    text-transform: uppercase;
    position: relative;
    z-index: 123;
    @include min-responsive($grid-desktop-large) {
        display: block;
        min-width: 1100px;
    }
    @include max-responsive($mntablet) {
        display: flex;
        flex-direction: column-reverse;
    }
    .footer {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin: 5px auto;
        @include max-responsive($mntablet) {
            &:first-child{
                flex-direction: column;
                align-items: center;
                padding: 15px 10px;
                text-align: center;
            }
        }
        p {
            font-size: 10px;
        }
        &__copyright {
            font-weight: 300;
        }
        &__share {
            padding-right: 30px;
            transform: translateY(10px);
        }
        &__link {
            font-size: 18px;
            padding: 0 5px;
            color: white;
            &:first-child {
                @include min-responsive($mntablet){
                    padding-left: 0;
                }
            }
            &:hover {
                .footer__item {
                    font-weight: 700;
                }
            }
        }
        &__menu {
            display: flex;
            justify-content: flex-start;
            width: 100%;
            @include max-responsive($mntablet) {
                justify-content: center;
                padding: 15px 0;
                border-bottom: 1px solid white;
            }
        }
        &__item {
            font-weight: 300;
            font-size: 14px;
            transition: .2s all ease-in-out;
        }
        &__pd {
            position: relative;
            padding: 0 10px;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: -2px;
                height: 90%;
                border-right: 1px solid $cl_blue;
            }
            &:last-child {
                &::before {
                    content: none;
                }
            }
        }
    }
}

.cd-popup {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(94, 110, 141, 0.9);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s;
  }
  .cd-popup.is-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0s;
    z-index: 12300;
  }
  
  .cd-popup-container {
    position: relative;
    width: 90%;
    max-width: 400px;
    margin: 4em auto;
    background: #FFF;
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
    /* Force Hardware Acceleration in WebKit */
    -webkit-backface-visibility: hidden;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .cd-popup-container p {
    padding: 3em 1em;
  }
  .cd-popup-container .cd-buttons:after {
    content: "";
    display: table;
    clear: both;
  }
  .cd-popup-container .cd-buttons li {
    float: left;
    width: 50%;
    list-style: none;
  }
  .cd-popup-container .cd-buttons a {
    display: block;
    height: 60px;
    line-height: 60px;
    text-transform: uppercase;
    color: #FFF;
    -webkit-transition: background-color 0.2s;
    -moz-transition: background-color 0.2s;
    transition: background-color 0.2s;
      font-family: 'AkzidenzGroteskBQ-BdCnd';
      font-style: italic; 
  }
  .cd-popup-container .cd-buttons li:first-child a {
    background: #000;
    color: #fff;
    border-radius: 0 0 0 .25em;
  }
  .cd-popup-container .cd-buttons li:first-child a:hover {
    background: $cl_blue;
    color: #000;
  }
  .no-touch .cd-popup-container .cd-buttons li:first-child a:hover {
    background-color: #fc8982;
  }
  .cd-popup-container .cd-buttons li:last-child a {
    background: #b6bece;
    border-radius: 0 0 .25em 0;
  }
  .cd-popup-container .cd-buttons li:last-child a:hover {
    background: $cl_grey;
    
  }
  .no-touch .cd-popup-container .cd-buttons li:last-child a:hover {
    background-color: #c5ccd8;
  }
  .cd-popup-container .cd-popup-close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 100px;
  }
  .cd-popup-container .cd-popup-close::before, .cd-popup-container .cd-popup-close::after {
    content: '';
    position: absolute;
    top: 12px;
    width: 14px;
    height: 3px;
    background-color: #8f9cb5;
  }
  .cd-popup-container .cd-popup-close::before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 8px;
  }
  .cd-popup-container .cd-popup-close::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    right: 8px;
  }
  .is-visible .cd-popup-container {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  @media only screen and (min-width: 1170px) {
    .cd-popup-container {
      margin: 18em auto;
    }
  }
  
  .cd-buttons {
      padding-left:0;
  }
.btn {
    &-start {
        position: absolute;
        bottom: 10%;
        left: 0;
        right: 0;
        margin-right: auto;
        margin-left: auto;
        text-align: center;
        width: 200px;
        -webkit-animation: updown 2s infinite;
        -o-animation: updown 2s infinite;
        animation: updown 2s infinite;
        z-index: 10;
        color: #fff;
        transition: all .2s ease-in-out;
        cursor: pointer;

        &:hover {
            .icon-down {
                border-left: 2px solid black;
                border-bottom: 2px solid black;
            }

            .icon-mouse {
                color: black;
            }
        }
    }

    &--grey {
        margin: 10px 0px;

        button {
            padding: 10px 20px;
            background: $cl_btn-grey;
            color: white;
            font-family: $font_primary;
            border: none;
            border-radius: 5px;
            letter-spacing: 4px;
            text-transform: uppercase;
            transition: all .2s ease-in-out;
            cursor: pointer;

            &:hover {
                background: $cl_dgrey;
                color: white;
                box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.35);
            }
        }
    }

    &--white {
        margin: 0 5px;

        button {
            padding: 10px 14px;
            background: white;
            color: $cl_dgrey;
            text-transform: uppercase;
            font-family: $font_primary;
            border: 1px solid $cl_dgrey;
            font-size: 12px;
            border-radius: 5px;
            letter-spacing: 1px;
            font-weight: 700;
            text-transform: uppercase;
            transition: all .2s ease-in-out;
            cursor: pointer;

            &:hover {
                background: $cl_dgrey;
                color: white;
                box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.35);
            }
        }
    }

    &--blue {
        margin: 20px 0;
        padding: 20px;
        background: $cl_lblue;
        color: white;
        text-transform: uppercase;
        font-family: $font_primary;
        border: 1px solid $cl_dgrey;
        font-size: 12px;
        border-radius: 5px;
        letter-spacing: 1px;
        font-weight: 700;
        text-transform: uppercase;
        transition: all .2s ease-in-out;
        cursor: pointer;
        display: inline-block;
        border: none;
        a{
            color: white;
            font: 500 20px/1.2 $font_body;
            @include max-responsive($mxphone){
                font-size: 13px;
            }
        }
        &:hover {
            background: white;
            box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.35);
            a{
                color: $cl_lblue;
            }
        }
    }
}

.button{
    &-active{
        background: $cl_blue!important;
    }
}